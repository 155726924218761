import { Accordion } from './Accordion';
import { TabList } from './Tablist';
import { isSet } from '../utils/isSet';
import DOMPurify from 'dompurify';

class SoftwareDownloads {
    constructor(root) {
        this.root = root;
        this.init();
    }

    init(){
        this.softwareDownloadsListStorageName = 'downloadlist';
        this.softwareDownloadsContainer = this.root.querySelector('.software-downloads__panels');
        this.softwareDownloadsListContainer = this.root.querySelector('#software-downloads__list');
        this.softwareDownloadsDownloadsContainer = document.querySelector('#software-downloads__generated-downloads');
        this.softwareDownloadsCountElements = document.querySelectorAll('.software-download-count--js');
        this.softwareListItemTemplate = document.querySelector('#software-list-item-template');
        this.softwareDownloadItemTemplate = document.querySelector('#software-download-item-template');
        this.softwareDownloadJSONURI = '/wp-content/uploads/software-downloads/downloads.json';
        this.softwareDownloadTablistContainer = this.root.querySelector('.tab-bar__tablist');

        this.loadJSON(this.softwareDownloadJSONURI, this.softwareDownloadsContainer);
    }

    convert2Nicename(string){
        let convertedString = string.replace(/-/g, ' ').replace(/_/g, ' ').toLowerCase();
        let convertedStringArray = convertedString.split(" ");
    
        for (let i = 0; i < convertedStringArray.length; i++) {
            convertedStringArray[i] = convertedStringArray[i][0].toUpperCase() + convertedStringArray[i].substr(1);
        }
    
        convertedString = convertedStringArray.join(" ");
    
        return convertedString;
    }

    async loadJSON(URL, rootElement){
        try {
            const res = await fetch(URL);
            let JSON = await res.json();
            this.renderSofwareDownloadList(JSON);
        } catch (err) {
            console.error(err);
        }
    }

    renderSofwareDownloadList(JSON){
        let panelsHTML = ``;
        let tabsHTML = ``;

        Object.entries(JSON).forEach(([os_key, os_value]) => {
            let os_nicename = this.convert2Nicename(os_key);

            if(this.getUserOS() === os_key){
                tabsHTML += `
                <button
                    id="tab--${os_key}"
                    class="tab"
                    role="tab"
                    aria-selected="true"
                    aria-controls="tabpanel--${os_key}"
                >
                ${os_nicename}
                </button>
                `;
            }else{
                tabsHTML += `
                <button
                    id="tab--${os_key}"
                    class="tab"
                    role="tab"
                    aria-selected="false"
                    aria-controls="tabpanel--${os_key}"
                >
                ${os_nicename}
                </button>
                `;
            }
        });
        
        Object.entries(JSON).forEach(([os_key, os_value]) => {
            let os_nicename = this.convert2Nicename(os_key);
            panelsHTML += `
            <div 
                id="tabpanel--${os_key}"
                class="tabpanel" 
                role="tabpanel"
                aria-labelledby="tab--${os_key}"
                hidden
                >
                <div class="accordion accordion--js">`;
                    Object.entries(os_value).forEach(([family_key, family_value]) => {
                        let family_nicename = "IBM SPSS " + this.convert2Nicename(family_key);
                        panelsHTML += `
                        <div class="accordion-item" accordion-item>
                            <button
                                id="accordion-trigger--${family_key}--${os_key}"
                                aria-expanded="false"
                                aria-controls="accordion-panel--${family_key}--${os_key}"
                            >
                                ${family_nicename}
                                <span class="indicator"></span>
                            </button>
                            <div 
                                id="accordion-panel--${family_key}--${os_key}"
                                role="region"
                                aria-labelledby="accordion-trigger--${family_key}--${os_key}"
                                >
                                <div accordion-content>
                                    <div class="accordion-item-group">`;
                                        let version_arr = Object.entries(family_value);
                                        version_arr.sort();
                                        const sortedVersions = version_arr.sort(function (a, b){
                                            a[0].localeCompare(b[0], undefined, { numeric: true, sensitivity: 'base' });
                                        });
                                        sortedVersions.reverse().forEach(([version_key, version_value]) => {
                                            let version_nicename = "Version " + this.convert2Nicename(version_key);
                                            panelsHTML += `
                                            <details>
                                                <summary>
                                                    <p>${version_nicename}</p>
                                                </summary>
                                                <div class="accordion-item__inner-group">
                                                `;
                                                Object.entries(version_value).forEach(([type_key, type_value]) => {
                                                    let type_nicename = this.convert2Nicename(type_key);
                                                    panelsHTML += `
                                                    <div class="inner-group">
                                                        <p>${type_nicename}</p>
                                                        <div class="download-list">
                                                        `;
                                                        if(typeof type_value === 'object' && type_value !== null){
                                                            let itemArray = Object.values(type_value);
                                                            itemArray.forEach((element) => {
                                                                let encodedURL = encodeURIComponent(element.key);
                                                                let element_nicename = element.key.replace(/-/g, ' ').replace(/_/g, ' ');
                                                                let elementTags = element.tags;
                                                                let flavourTag = ``;
                                                                let architectureTag = ``;
                                                                if(elementTags.hasOwnProperty('flavour') == true){
                                                                    if(element.tags.flavour == 'premium' || element.tags.flavour == 'professional' || element.tags.flavour == 'server'){
                                                                        flavourTag += `<div class="flavour pill ` + element.tags.flavour + `">` + this.convert2Nicename(element.tags.flavour) +`</div>`
                                                                    }
                                                                }
                                                                if(elementTags.hasOwnProperty('architecture') == true){
                                                                    if(element.tags.architecture == 'all'){
                                                                        architectureTag += `<div class="architecture pill">32 & 64 Bit</div>`;
                                                                    }else{
                                                                        architectureTag += `<div class="architecture pill">` + this.convert2Nicename(element.tags.architecture) +`</div>`;
                                                                    }
                                                                    
                                                                }
                                                                panelsHTML += `
                                                                <div class="download-item" data-id="` + element.id + `">
                                                                    <div class="download-item-info">
                                                                        <div class="download-item-pills">` +
                                                                            flavourTag + architectureTag +
                                                                        `</div>
                                                                        <p>` + element_nicename + `</p>
                                                                    </div>
                                                                    <button 
                                                                        class="software-download-add software-download-add--js" 
                                                                        data-nicename="` + element_nicename + `" 
                                                                        data-id="` + element.id + `" 
                                                                        data-key="` + encodedURL + `" 
                                                                        data-size="` + element.size + `"
                                                                        >+ Add</button>
                                                                </div>
                                                                `;
                                                            });
                                                        }
                                                        panelsHTML += `
                                                        </div>
                                                    </div>`;
                                                });
                                                panelsHTML += `
                                                </div>
                                            </details>`;
                                        });
                                        panelsHTML += `
                                    </div>
                                </div>
                            </div>
                        </div>
                        `;
                    });
                    panelsHTML += `
                </div>
            </div>
            `;
        });

        let cleanPanelsHTML = DOMPurify.sanitize(panelsHTML, {ADD_ATTR: ['accordion-item', 'accordion-content']});
        let cleanTabsHTML = DOMPurify.sanitize(tabsHTML, {ADD_ATTR: ['accordion-item', 'accordion-content']});

        this.softwareDownloadsContainer.insertAdjacentHTML('beforeend', cleanPanelsHTML);
        this.softwareDownloadTablistContainer.insertAdjacentHTML('beforeend', cleanTabsHTML);

        this.initAccordion();
        this.initTabList();

        this.initAfterLoad();
    }

    initAfterLoad(){
        this.softwareDownloadsAddButtons = this.root.querySelectorAll('.software-download-add--js');
        this.softwareDownloadsAddButtons.forEach((softwareDownloadAddButton) => {
            softwareDownloadAddButton.addEventListener("click", this.handleAddButtonClick.bind(this));
        });

        const softwareDownloads = this.getUserDownloadsList();

        if(softwareDownloads.length !== 0){
            softwareDownloads.forEach((softwareDownload) => {
                const softwareDownloadItems = this.buildSoftwareDownloadItemElements(
                    softwareDownload.id, 
                    softwareDownload.nicename,
                    softwareDownload.key,
                    softwareDownload.size
                );

                const originElement = this.root.querySelector('.software-download-add--js[data-id="' + softwareDownload.id + '"]');
                originElement.textContent = 'Added';
                originElement.setAttribute('disabled', "");

                this.softwareDownloadsListContainer.prepend(softwareDownloadItems[0]);
                this.softwareDownloadsDownloadsContainer.prepend(softwareDownloadItems[1]);
            });
        }

        this.updateUserDownloadsCount();
        this.handleStateUpdate();
    }

    initAccordion(){
        const accordionElements = this.root.querySelectorAll('.accordion--js');
        accordionElements.forEach((accordionElement)=> {
            new Accordion(
                accordionElement,
                {
                    collapsible: true
                }
            );
        });
    }

    initTabList(){
        const tablistElement = this.root.querySelector('.tab-bar--js');
        new TabList(
            tablistElement,
            {}
        );
    }

    handleAddButtonClick(e){
        const id = e.currentTarget.dataset.id;
        this.addSoftwareDownloadItem(id);
    }

    handleRemoveButtonClick(e){
        const id = e.currentTarget.dataset.id;
        this.removeSoftwareDownloadItem(id);
    }

    addSoftwareDownloadItem(id){
        if(!isSet(id)) return;
        
        const originElement = this.root.querySelector('.software-download-add--js[data-id="' + id + '"]');
        const nicename = originElement.dataset.nicename;
        const key = originElement.dataset.key;
        const size = originElement.dataset.size;

        if(!isSet(nicename) || !isSet(key) || !isSet(size)) return;
        
        const listElement = this.buildSoftwareDownloadItemElements(id, nicename, key, size)[0];
        const downloadElement = this.buildSoftwareDownloadItemElements(id, nicename, key, size)[1];
        if(!isSet(listElement) || !isSet(downloadElement)) return;

        const object = this.buildSoftwareDownloadItemObject(id, nicename, key, size);
        if(!isSet(object)) return;

        const softwareDownloads = this.getUserDownloadsList();

        softwareDownloads.push(object);
        localStorage.setItem(
            this.softwareDownloadsListStorageName,
            JSON.stringify(softwareDownloads)
        );

        this.softwareDownloadsListContainer.prepend(listElement);
        this.softwareDownloadsDownloadsContainer.prepend(downloadElement);

        this.updateUserDownloadsCount();
        originElement.textContent = 'Added';
        originElement.setAttribute('disabled', "");

        this.handleStateUpdate();
    }

    buildSoftwareDownloadItemElements(
        id = null,
        nicename = null,
        key = null,
        size = null
    ){
        if(!isSet(id) || !isSet(nicename) || !isSet(key) || !isSet(size)) return;

        const softwareListElement = this.softwareListItemTemplate.content.cloneNode(true).children[0];
        const softwareDownloadElement = this.softwareDownloadItemTemplate.content.cloneNode(true).children[0];

        softwareListElement.dataset.id = id;
        softwareListElement.querySelector('p').textContent = nicename;
        softwareListElement.querySelector('.software-list-item__remove--js').dataset.id = id;
        softwareListElement.querySelector('.software-list-item__remove--js').addEventListener("click", this.handleRemoveButtonClick.bind(this));

        softwareDownloadElement.dataset.id = id;
        softwareDownloadElement.querySelector('p').textContent = nicename;
        softwareDownloadElement.querySelector('span').textContent = this.getformattedBytes(size);
        softwareDownloadElement.querySelector('.wp-block-button__link').href = 'https://spss-software-store.s3.amazonaws.com/' + key;

        return [softwareListElement, softwareDownloadElement];
    }

    buildSoftwareDownloadItemObject(
        id = null,
        nicename = null,
        key = null,
        size = null
    ){
        if(!isSet(id) || !isSet(nicename) || !isSet(key) || !isSet(size)) return;

        const object = {
            "id" : id,
            "nicename" : nicename,
            "key" : key,
            "size" : size
        }

        return object;
    }

    removeSoftwareDownloadItem(id = null){
        this.removeSoftwareDownloadItemObject(id);
        this.removeSoftwareDownloadItemElement(id);
        this.updateUserDownloadsCount();
        this.handleStateUpdate();
    }

    removeSoftwareDownloadItemElement(id = null){
        const itemElement = this.root.querySelector('.software-list__item[data-id="' + id + '"]');
        const downloadElement = document.querySelector('.software-download__item[data-id="' + id + '"]');
        const originElement = this.root.querySelector('.software-download-add--js[data-id="' + id + '"]');

        if(isSet(itemElement)){
            itemElement.remove();
        }

        if(isSet(downloadElement)){
            downloadElement.remove();
        }

        if(isSet(originElement)){
            originElement.textContent = 'Add +';
            originElement.removeAttribute('disabled');
        }
    }

    removeSoftwareDownloadItemObject(id = null){
        if (this.searchUserDownloadsList(id, this.getUserDownloadsList()) === false) return;

        const itemIndex = this.searchUserDownloadsList(id, this.getUserDownloadsList());

        const softwareDownloads = this.getUserDownloadsList();

        softwareDownloads.splice(itemIndex, 1);

        localStorage.setItem(this.softwareDownloadsListStorageName, JSON.stringify(softwareDownloads));
    }

    getUserDownloadsList(){
        if(!isSet(localStorage.getItem(this.softwareDownloadsListStorageName))){
            localStorage.setItem(this.softwareDownloadsListStorageName, "[]");
        }

        this.softwareDownloads = JSON.parse(
            localStorage.getItem(this.softwareDownloadsListStorageName) || "[]"
        );

        return this.softwareDownloads;
    }

    getUserDownloadsCount(){
        this.softwareDownloads = this.getUserDownloadsList().length
        return this.softwareDownloads;
    }

    updateUserDownloadsCount(){
        this.softwareDownloadsCountElements.forEach((softwareDownloadCountElement) => {
            softwareDownloadCountElement.textContent = this.getUserDownloadsCount();
        })
    }

    handleStateUpdate(){
        const count = this.getUserDownloadsCount();
        const trigger = this.root.querySelector('#software-downloads-confirmation');

        if(count === 0){
            let message = this.root.querySelector('.message');
            if(!isSet(message)){
                let message = document.createElement('p');
                message.classList.add('message');
                message.textContent = 'You have not added any downloads.';
                this.softwareDownloadsListContainer.innerHTML = '';
                this.softwareDownloadsListContainer.append(message);
            }

            trigger.classList.add('is-hidden');
        } else{
            let message = this.root.querySelector('.message');
            
            if(isSet(message)){
                message.remove();
            }

            trigger.classList.remove('is-hidden');
        }
    }

    searchUserDownloadsList(key, array){
        for (let i = 0; i < array.length; i++) {
            if (array[i].id === key) {
                return i;
            }
        }
        return false;
    }

    getUserOS(){
        let os = navigator.userAgent;

        if (os.search('Windows')!==-1){
            return 'windows';
        } else if (os.search('Mac')!==-1){
            return 'mac';
        } else if (os.search('Linux')!==-1 && os.search('X11')!==-1){
            return 'linux';
        } else{
            return 'windows';
        }
    }

    getformattedBytes(bytes, decimals = 2){
        if (!+bytes) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    }
}

exports.SoftwareDownloads = SoftwareDownloads