class StickyHeader {
    constructor (domNode) {
        this.documentElm = document.documentElement;
        this.window = window;
        this.prevScroll = this.window.scrollY || this.documentElm.scrollTop;
        this.currentScroll;
        this.headerHeight;
        this.direction = 0;
        this.prevDirection = 0;
        this.stickyHeader = domNode
        this.stickyOffset = domNode.offsetTop;
        this.header

        this.checkOffset = this.checkOffset.bind(this);
        window.addEventListener(
            'scroll', 
            this.checkOffset
        );

        this.checkScroll = this.checkScroll.bind(this)
        window.addEventListener(
            'scroll', 
            this.checkScroll
        );

        this.setMargin = this.setMargin.bind(this)
        document.addEventListener(
            'DOMContentLoaded', 
            this.setMargin
        );
        window.addEventListener(
            'resize', 
            this.setMargin
        );
    }

    checkOffset() {
        const headerElm = this.stickyHeader;
        const stickyOffset = this.stickyOffset;
        const pageOffset = window.scrollY;

        if (pageOffset > stickyOffset) {
            headerElm.classList.add("sticky");
        } else {
            headerElm.classList.remove("sticky");
        }
    }

    toggleHeader(direction, currentScroll) {
        const headerElm = this.stickyHeader;

        if (direction === 2 && currentScroll > this.headerHeight) { 
            headerElm.classList.add('hide');
            this.prevDirection = direction;
        }
        else if (direction === 1) {
            headerElm.classList.remove('hide');
            this.prevDirection = direction;
        }
    }

    checkScroll() {
        //Find the direction of scroll: 0 = initial, 1 = up, 2 = down
        let currentScroll = this.window.scrollY || this.documentElm.scrollTop;
        let direction = this.direction;
        let prevScroll = this.prevScroll;
        let prevDirection = this.prevDirection;

        if (currentScroll > prevScroll) { //Scrolling Up
            direction = 2;
        }
        else if (currentScroll < prevScroll) { //Scrolling Down
            direction = 1;
        }

        if (direction !== prevDirection) {
            this.toggleHeader(direction, currentScroll);
        }

        this.prevScroll = currentScroll;
    }

    setMargin() {
        let headerHeight = this.stickyHeader.offsetHeight;
        this.documentElm.style.setProperty('--masthead-height', headerHeight + 'px');
        this.headerHeight = headerHeight;
    }
}
  
exports.StickyHeader = StickyHeader