import { Accordion } from './Accordion';
import { isSet } from '../utils/isSet';

class TabList {
    /**
     * @param {HTMLElement | Element} domNode 
     * @param {Object} opts 
     */
    constructor(domNode, opts) {

        /** @type {TabListDefault} */
        const DEFAULTS = {
            onChange: () => { },
            orientation: 'horizontal',
            disableActiveTab: false,
            changeOnNavigation: false,
            moveFocusOnSelect: false,
            convertToAccordion: false,
			accordionBreakpoint: '767'
        }

        /** @type {TabListDefault} */
        this.options = Object.assign(DEFAULTS, opts);

        /** @type {Element | HTMLElement} */
        this.domNode = domNode;

        this.init();
    }

    init(){
        this.isAccordion = false;
        this.tabList = this.domNode.querySelector('[role="tablist"]');
        this.tabs = Array.from(this.domNode.querySelectorAll('[role=tab]'));
        this.activeTabs = Array.from(this.domNode.querySelectorAll('[role=tab]:not([disabled])'));
        this.tabPanelContainer = this.domNode.querySelector('.tabpanels');

        /** @type {Array<HTMLElement>} */
        this.tabpanels = [];

        for (let i = 0; i < this.tabs.length; i += 1) {
            const tab = this.tabs[i];
            const tabpanel = document.getElementById(tab.getAttribute('aria-controls'));

            tab.tabIndex = -1;

            if(!this.selected && tab.getAttribute('aria-selected') === 'true'){
                this.selected = tab;
            }else{
                tab.setAttribute('aria-selected', 'false');
            }

            this.tabpanels.push(tabpanel);

            tab.addEventListener('keydown', this.onKeydown.bind(this));
            tab.addEventListener('click', this.onClick.bind(this));

            if (!this.firstTab) {
                this.firstTab = tab;
            }

            this.lastTab = tab;
        }

        (this.selected) ? this.setSelectedTab(this.selected, true) : this.setSelectedTab(this.firstTab, true);

        this.domNode.classList.add('has-initialised');

        window.addEventListener('load', this.handleWindowResize.bind(this));
		window.addEventListener('resize', this.handleWindowResize.bind(this));
    }

    /**
     * Refreshes the class component and calls init() and does any necessary resets
     */
    refresh() {
        this.init();
    }

    /**
     * Handles changing a tab
     * @param {HTMLElement} targetTab 
     * @param {Event | KeyboardEvent | null} event 
     */
    handleTabChange(targetTab, event = null) {
        this.setSelectedTab(targetTab);
        this.options.onChange(this, event);
    }

    /**
     * 
     */
    setActiveTabs(){
        this.activeTabs = Array.from(this.domNode.querySelectorAll('[role=tab]:not([disabled])'));
        this.firstActiveTab = null;

        for (let i = 0; i < this.activeTabs.length; i += 1) {
            const tab = this.activeTabs[i];

            if (!this.firstActiveTab) this.firstActiveTab = tab;

            this.lastActiveTab = tab;
        }
        
        this.nextTab = this.getNextTab();
        this.prevTab = this.getPrevTab();
    }

    /**
     * Sets the passed tab and corresponding panel to be selected
     * @param {Element} targetTab The target tab to select
     * @param {Boolean} onInit Is this being called at point of initialisation
     */
    setSelectedTab(targetTab, onInit = false){
        for (let i = 0; i < this.tabs.length; i += 1) {
            const tab = this.tabs[i];

            if (targetTab.id === tab.id) {
                this.selected = this.tabs[i];
                tab.setAttribute('aria-selected', 'true');
                tab.removeAttribute('tabindex');

                if(isSet(this.tabpanels[i])){
                    this.tabpanels[i].removeAttribute('hidden');
                }

                if(this.options.disableActiveTab === true){
                    tab.setAttribute('disabled', '');
                    tab.tabIndex = -1;

                }
            }else{
                tab.setAttribute('aria-selected', 'false');
                tab.removeAttribute('disabled');
                tab.tabIndex = -1;
                if(isSet(this.tabpanels[i]) && onInit === false){
                    this.tabpanels[i].setAttribute('hidden', '');
                }
            }
        }

        this.setActiveTabs();
    }

    getNextTab(tab = null){
        let nextTab;
        let tabList;
        const currentTab = tab ?? this.selected;
        

        if(this.options.disableActiveTab){
            tabList = this.activeTabs;
        } else {
            tabList = this.tabs;
        }

        const i = tabList.indexOf(currentTab);

        if(currentTab.id === this.lastActiveTab.id){
            nextTab = this.firstActiveTab;
        }else{
            nextTab = tabList[i + 1];
        }
        return nextTab;
    }

    getPrevTab(tab = null){
        let prevTab;
        let tabList;
        const currentTab = tab ?? this.selected;

        if(this.options.disableActiveTab){
            tabList = this.activeTabs;
        } else {
            tabList = this.tabs;
        }

        const i = tabList.indexOf(currentTab);
        if(currentTab.id === this.firstActiveTab.id){
            prevTab = this.lastActiveTab;
        }else{
            prevTab = tabList[i - 1];
        }
        return prevTab;
    }

    /**
     * Moves focus to passed tab
     * @param {Element} targetTab 
     */
    moveFocusToTab(targetTab) {
        targetTab.focus();
    }

    /**
     * Handles keydown events on tabs
     * @param {KeyboardEvent} event 
     */
    onKeydown(event) {
        const tgt = event.currentTarget;
        const change = this.options.changeOnNavigation;
        const orientation = this.options.orientation;
        let flag = false;

        switch (event.key) {
            case 'ArrowLeft':
                if(orientation === 'horizontal'){
                    if(change){
                        this.handleTabChange(this.prevTab);
                        this.moveFocusToTab(this.selected);
                    }else{
                        this.moveFocusToTab(this.getPrevTab(tgt));
                    }
                    flag = true;
                }
                break;

            case 'ArrowRight':
                if(orientation === 'horizontal'){
                    if(change){
                        this.handleTabChange(this.nextTab);
                        this.moveFocusToTab(this.selected);
                    }else{
                        this.moveFocusToTab(this.getNextTab(tgt));
                    }
                    flag = true;
                }
                break;

            case 'ArrowUp':
                if(orientation === 'vertical'){
                    if(change){
                        this.handleTabChange(this.prevTab);
                        this.moveFocusToTab(this.selected);
                    }else{
                        this.moveFocusToTab(this.getPrevTab(tgt));
                    }
                    flag = true;
                }
                break;

            case 'ArrowDown':
                if(orientation === 'vertical'){
                    if(change){
                        this.handleTabChange(this.nextTab);
                        this.moveFocusToTab(this.selected);
                    }else{
                        this.moveFocusToTab(this.getNextTab(tgt));
                    }
                    flag = true;
                }
                break;

            case 'Home':
                if(change){
                    this.handleTabChange(this.firstActiveTab);
                    this.moveFocusToTab(this.selected);
                }else{
                    this.moveFocusToTab(this.firstActiveTab);
                }
                flag = true;
                break;

            case 'End':
                if(change){
                    this.handleTabChange(this.lastActiveTab);
                    this.moveFocusToTab(this.selected);
                }else{
                    this.moveFocusToTab(this.lastActiveTab);
                }
                flag = true;
                break;

            default:
                break;
        }

        if (flag) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    /**
     * Handles click events on tabs
     * @param {Event} event 
     */
    onClick(event) {
        this.handleTabChange(event.currentTarget, event);
        if(this.options.moveFocusOnChange === true){
            this.moveFocusToTab(this.nextTab);
        }
    }

    handleWindowResize(){
		if(this.options.convertToAccordion === false){
			return;
		}

		if (window.innerWidth < parseInt(this.options.accordionBreakpoint)) {
            if(this.isAccordion == true){
                return;
            }
			this.handleAccordionConvert();
		}else{
            if(this.isAccordion == false){
                return;
            }
            this.handleTabListConvert();
        }
    }

    handleAccordionConvert(){
        if(!isSet(this.accordion)){

            const accordionElement = document.createElement('div');
            accordionElement.classList.add('accordion', 'accordion-js', 'has-css-animation');

            for (let i = 0; i < this.tabpanels.length; i += 1) {
                const tabPanel = this.tabpanels[i];
                const id = tabPanel.id;
                const tabPanelContent = tabPanel.querySelector('.tabpanel__inner').innerHTML;
                const tabPanelTitle = tabPanel.dataset.title;
                
                //Accordion Item
                const accordionItemElement = document.createElement('div');
                accordionItemElement.classList.add('block-accordion-item');
                accordionItemElement.setAttribute('accordion-item', '');

                //Accordion Item Trigger
                const accordionItemTrigger = document.createElement('button');
                accordionItemTrigger.id = 'trigger-' + id;
                accordionItemTrigger.setAttribute('aria-expanded', 'false');
                accordionItemTrigger.setAttribute('aria-controls', 'panel-' + id);
                accordionItemTrigger.textContent = tabPanelTitle;

                //Accordion Item Trigger Indicator
                const accordionItemTriggerIndicator = document.createElement('span');
                accordionItemTriggerIndicator.classList.add('indicator');

                //Accordion Item Panel
                const accordionItemPanel = document.createElement('div');
                accordionItemPanel.id = 'panel-' + id;
                accordionItemPanel.setAttribute('aria-labelledby', 'trigger-' + id);
                accordionItemPanel.setAttribute('role', 'region');

                //Accordion Item Panel Content
                const accordionItemPanelContent = document.createElement('div');
                accordionItemPanelContent.setAttribute('accordion-content', '');

                //Accordion Item Panel Content Inner
                const accordionItemPanelContentInner = document.createElement('div');
                accordionItemPanelContentInner.classList.add('accordion-item-group');
                accordionItemPanelContentInner.innerHTML = tabPanelContent;

                //Build Accordion Item Trigger
                accordionItemTrigger.appendChild(accordionItemTriggerIndicator);

                //Build Accordion Item Panel
                accordionItemPanelContent.appendChild(accordionItemPanelContentInner);
                accordionItemPanel.appendChild(accordionItemPanelContent);

                //Build Accordion Item
                accordionItemElement.appendChild(accordionItemTrigger);
                accordionItemElement.appendChild(accordionItemPanel);

                accordionElement.appendChild(accordionItemElement);
            }

            this.accordion = accordionElement
        }

		this.tabList.remove();
        this.tabPanelContainer.remove();
        this.domNode.appendChild(this.accordion);

        this.accordionApi = new Accordion(
            this.accordion,
            {
                type: "single",
                collapsible: true
            }
        );

		this.isAccordion = true;
    }

    handleTabListConvert(){
        this.accordionApi.destroy();
        this.accordion.remove();
		this.domNode.insertAdjacentElement('afterbegin' , this.tabList);
        this.domNode.insertAdjacentElement('beforeend' , this.tabPanelContainer);

		const accordionHeaders = this.domNode.querySelectorAll('.block-tabs__accordion-header');
		for (let i = 0; i < accordionHeaders.length; i += 1) {
			accordionHeaders[i].remove();
		}

		this.isAccordion = false;
	}
}

exports.TabList = TabList